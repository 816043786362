import {
  Button,
  EmptyState,
  injectGlobalMetricStyles,
  LoadingIcon,
  space,
  styled,
} from '@meterup/atto';
import { ResourceNotFoundError, useSetMaximumScale } from '@meterup/common';
import { getGraphQLError, GraphQLExtensionErrorCode } from '@meterup/graphql';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import Navbar from './components/Navbar';

function retry(count: number, error: unknown) {
  if (error instanceof ResourceNotFoundError) {
    return false;
  }

  if (error instanceof Error) {
    const graphqlError = getGraphQLError(error);
    return graphqlError?.extensions?.code === GraphQLExtensionErrorCode.Unauthorized;
  }

  return count < 3;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      suspense: true,
      retry,
    },
  },
});

const AppFrame = styled('div', {
  margin: '0 auto',
  display: 'grid',
  gridTemplateRows: '48px 1fr',
  height: '100vh',
});

const WidthContainer = styled('div', {
  margin: '0 auto',
  maxWidth: '480px',
  padding: '1em 1em 3em',
});

const ScrollingContent = styled('div', {
  overflowY: 'auto',
});

function App() {
  useEffect(() => {
    injectGlobalMetricStyles();
  }, []);

  useSetMaximumScale();

  return (
    <QueryClientProvider client={queryClient}>
      <AppFrame>
        <Navbar
          endActions={
            <Button
              arrangement="hidden-label"
              icon="arrows-rotate"
              size="medium"
              variant="secondary"
              onClick={() => queryClient.refetchQueries()}
            >
              Refresh
            </Button>
          }
        />
        <ScrollingContent>
          <Suspense
            fallback={
              <EmptyState
                heading={<LoadingIcon size={space(24)} />}
                subheading="Loading network..."
              />
            }
          >
            <WidthContainer>
              <Outlet />
            </WidthContainer>
          </Suspense>
        </ScrollingContent>
      </AppFrame>
    </QueryClientProvider>
  );
}

export default App;
