import type { BadgeVariant } from '@meterup/atto';
import type { SortingState } from '@meterup/common';
import type { api } from '@meterup/proto';
import { Badge, HardwareIcon, HStack, SearchInput, Small, space, VStack } from '@meterup/atto';
import { AutoTable, colors, createColumnHelper, styled } from '@meterup/common';
import { useState } from 'react';

import type { AccessPointProps, SwitchProps } from '../api/api';
import { useDevicesData } from '../api/api';
import { BandIconAndBadge } from './BandIconAndBadge';
import { Section } from './Section';

const SummaryGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
  marginBottom: '-16px',
});

const SummaryGridItem = styled('div', {
  vStack: 2,
  padding: '$10',
  paddingBottom: 0,
  textAlign: 'center',
});

const DeviceMeta = styled('div', {
  color: colors.gray500,
  variants: {
    mono: {
      true: {
        fontFamily: '$mono',
      },
    },
  },
});

const SpeedWarning = styled('div', {
  hStack: '$4',
  color: colors.red500,
});

function isSlowSpeed(speed?: number | null): boolean {
  return speed !== null && speed !== undefined && speed < 1000;
}

function statusToLabel(status: string, speed?: number | null): string {
  switch (status) {
    case 'online':
      if (isSlowSpeed(speed)) {
        return 'Degraded';
      }
      return 'Connected';
    case 'offline':
      return 'Offline';
    case 'draft':
      return 'Pending';
    default:
      return 'Unknown';
  }
}

function statusToVariant(status: string, speed?: number | null): BadgeVariant {
  switch (status) {
    case 'online':
      if (isSlowSpeed(speed)) {
        return 'negative';
      }
      return 'positive';
    case 'offline':
      return 'negative';
    case 'draft':
      return 'alternative';
    default:
      return 'neutral';
  }
}

function ControllerBadgeAndIcon({ connected }: { connected: string }) {
  const variant = connected === 'online' ? 'positive' : 'negative';

  return (
    <VStack spacing={space(8)}>
      <BandIconAndBadge
        variant={variant}
        size="large"
        badge={
          <Badge ends="pill" size="small" variant={variant}>
            {variant === 'positive' ? 'On' : 'Offline'}
          </Badge>
        }
        icon={<HardwareIcon hardware="security-appliance" size={32} variant="detailed" />}
      />
    </VStack>
  );
}

function APBadgeAndIcon({ numOnline, numOffline }: { numOnline: number; numOffline: number }) {
  const variant = numOffline === 0 ? 'positive' : 'negative';

  return (
    <BandIconAndBadge
      variant={variant}
      size="large"
      badge={
        <Badge ends="pill" size="small" variant={variant}>
          {numOnline}/{numOnline + numOffline}
        </Badge>
      }
      icon={<HardwareIcon hardware="access-point" size={32} variant="detailed" />}
    />
  );
}

function SwitchesBadgeAndIcon({
  numOnline,
  numOffline,
}: {
  numOnline: number;
  numOffline: number;
}) {
  const variant = numOffline === 0 ? 'positive' : 'negative';

  return (
    <BandIconAndBadge
      variant={variant}
      size="large"
      badge={
        <Badge ends="pill" size="small" variant={variant}>
          {numOnline}/{numOnline + numOffline}
        </Badge>
      }
      icon={<HardwareIcon hardware="switch" size={32} variant="detailed" />}
    />
  );
}

const apColumnHelper = createColumnHelper<AccessPointProps>();

const apColumns = [
  apColumnHelper.accessor('name', {
    header: 'Name',
    cell: (info) => (
      <HStack spacing={space(8)}>
        <VStack spacing={space(4)}>
          {info.getValue()}
          <DeviceMeta>Gen {info.row.original.generation}</DeviceMeta>
        </VStack>
      </HStack>
    ),
  }),
  apColumnHelper.accessor('status', {
    header: 'Status',
    meta: { width: 80 },
    cell: (info) => {
      const { speed, status } = info.row.original;
      return (
        <Badge size="small" variant={statusToVariant(status, speed)}>
          {statusToLabel(status, speed)}
        </Badge>
      );
    },
  }),
  apColumnHelper.accessor('speed', {
    header: 'Link speed',
    meta: { width: 96 },
    cell: (info) => {
      const speed = info.getValue();

      if (speed === null || speed === undefined) {
        return '-';
      }

      if (isSlowSpeed(speed)) {
        return <SpeedWarning>{speed} Mbps</SpeedWarning>;
      }

      return `${speed} Mbps`;
    },
  }),
];

function AccessPoints({ accessPoints }: { accessPoints: AccessPointProps[] | undefined }) {
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  if (!accessPoints?.length) return null;

  return (
    <>
      <SearchInput
        aria-label="Filter access points"
        scope="scoped"
        value={globalFilter}
        onChange={setGlobalFilter}
        placeholder="..."
      />
      <AutoTable
        columns={apColumns}
        data={accessPoints ?? []}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
        globalFilter={globalFilter}
        size="small"
        enableRowSelection={false}
      />
    </>
  );
}

const switchColumnHelper = createColumnHelper<SwitchProps>();

const switchColumns = [
  switchColumnHelper.accessor('name', {
    header: 'Name',
    cell: (info) => {
      const { serialNumber, rackMountUnitIndexes } = info.row.original;

      return (
        <HStack spacing={space(8)}>
          <VStack spacing={space(4)}>
            {info.getValue()}
            {serialNumber ? (
              <DeviceMeta mono>{serialNumber}</DeviceMeta>
            ) : (
              <DeviceMeta>No serial number</DeviceMeta>
            )}
            {rackMountUnitIndexes && (
              <DeviceMeta>
                Rack {rackMountUnitIndexes.length === 1 ? 'unit' : 'units'}:{' '}
                {rackMountUnitIndexes.join('-')}
              </DeviceMeta>
            )}
          </VStack>
        </HStack>
      );
    },
  }),
  switchColumnHelper.accessor('status', {
    header: 'Status',
    meta: { width: 100 },
    cell: (info) => {
      const status = info.getValue();
      return (
        <Badge size="small" variant={statusToVariant(status)}>
          {statusToLabel(status)}
        </Badge>
      );
    },
  }),
];

function Switches({ switches }: { switches: SwitchProps[] | undefined }) {
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  if (!switches?.length) return null;

  return (
    <>
      <SearchInput
        aria-label="Filter access points"
        scope="scoped"
        value={globalFilter}
        onChange={setGlobalFilter}
      />
      <AutoTable
        columns={switchColumns}
        data={switches ?? []}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
        globalFilter={globalFilter}
        size="small"
        enableRowSelection={false}
      />
    </>
  );
}

function relativeTimeAgo(date: Date): string {
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  if (diff < 10_000) {
    return 'a few seconds ago';
  }

  if (diff < 60_000) {
    return 'less than a minute ago';
  }

  const minutes = Math.floor(diff / 60_000);
  const unit = minutes === 1 ? 'minute' : 'minutes';

  return `${Math.floor(diff / 60_000)} ${unit} ago`;
}

function LastUpdated({ dataUpdatedAt }: { dataUpdatedAt: number }) {
  return <Small italicize="italic">Last updated {relativeTimeAgo(new Date(dataUpdatedAt))}</Small>;
}

const wanColumnHelper = createColumnHelper<api.WANMetrics>();

const wanColumns = [
  wanColumnHelper.accessor('interface_name', {
    header: 'Name',
    meta: { width: 60 },
    cell: (info) => (
      <HStack spacing={space(8)}>
        <VStack spacing={space(4)}>{info.getValue()}</VStack>
      </HStack>
    ),
  }),
  wanColumnHelper.accessor('link_status_is_online', {
    header: 'Status',
    meta: { width: 100 },
    cell: (info) => {
      if (info.getValue() === true) {
        return (
          <Badge size="small" variant="positive">
            Connected
          </Badge>
        );
      }
      return (
        <Badge size="small" variant="negative">
          Not connected
        </Badge>
      );
    },
  }),
  wanColumnHelper.accessor('quality', {
    header: 'Uplink',
    meta: { width: 100 },
    cell: (info) => {
      if (info.getValue() === 1) {
        return (
          <Badge size="small" variant="positive">
            Yes
          </Badge>
        );
      }
      return (
        <Badge size="small" variant="negative">
          No
        </Badge>
      );
    },
  }),
];

function WANMetrics({ metrics }: { metrics: api.WANMetrics[] }) {
  return (
    <AutoTable columns={wanColumns} data={metrics ?? []} size="small" enableRowSelection={false} />
  );
}

function PendingBadge({ count }: { count: number }) {
  return <Small>{count} pending</Small>;
}

export default function StatusBar() {
  const { dataUpdatedAt, controller, sortedAPs, sortedSwitches, switches, accessPoints } =
    useDevicesData();

  return (
    <VStack spacing={space(36)}>
      <SummaryGrid>
        <SummaryGridItem>
          <ControllerBadgeAndIcon connected={controller.connected_status} />
          <Small>Controller</Small>
        </SummaryGridItem>
        <SummaryGridItem>
          <APBadgeAndIcon
            numOnline={sortedAPs.online?.length ?? 0}
            numOffline={sortedAPs.offline?.length ?? 0}
          />
          <VStack align="center">
            <Small>Access points</Small>
            {sortedAPs.draft?.length ? <PendingBadge count={sortedAPs.draft.length} /> : null}
          </VStack>
        </SummaryGridItem>
        {switches?.length ? (
          <SummaryGridItem>
            <SwitchesBadgeAndIcon
              numOnline={sortedSwitches.online?.length ?? 0}
              numOffline={sortedSwitches.offline?.length ?? 0}
            />
            <VStack align="center">
              <Small>Switches</Small>
              {sortedSwitches.draft?.length ? (
                <PendingBadge count={sortedSwitches.draft.length} />
              ) : null}
            </VStack>
          </SummaryGridItem>
        ) : null}
      </SummaryGrid>

      {controller.wan_metrics.length > 0 && (
        <Section title="WAN ports" icon="security-appliance">
          <WANMetrics metrics={controller.wan_metrics} />
          <LastUpdated dataUpdatedAt={dataUpdatedAt} />
        </Section>
      )}

      {switches.length > 0 && (
        <Section icon="switch" title="Switches">
          <Switches switches={switches} />
          <LastUpdated dataUpdatedAt={dataUpdatedAt} />
        </Section>
      )}

      <Section icon="access-point" title="Access points">
        <AccessPoints accessPoints={accessPoints} />
        <LastUpdated dataUpdatedAt={dataUpdatedAt} />
      </Section>
    </VStack>
  );
}
